<template>
  <div class="page">
    <div class="cloud">
      <img src="@/assets/images/cloud.png" />
    </div>
    <div class="birds">
      <img src="@/assets/images/birds.png" />
    </div>
    <div class="main-content">
      <div class="animation-block-1">
        <div class="text">上海镜台国际教育</div>
      </div>
      <div class="animation-block-2">
        <div class="text">
          上海镜台教育（Mirror Edu）总部位于上海
          <br />在英国伦敦、剑桥、牛津等地均设有分办事处
          <br />为来自国内、新加坡、英国、新西兰等国家地区的
          <br />国际教育尖子生提供独特而有效的培训、教育服务
          <br />
          <br />集结了超过300名来自牛津、剑桥的导师
          <br />200余名来自QS排名世界前十的名校师资
          <br />10+所国际学校牛剑名校培优合作方
          <br />Mirror“线上微科研”获得高度好评
          <br />
          <br />2023-2024申请季收获
          <br />剑桥面邀74封，斩获Offer-37封
          <br />牛津面邀38封，斩获Offer-17封
          <br />牛剑面邀共112封，斩获Offer共54封
          <br />MIRROR是一个重情怀，讲质量的机构
          <br />一切从学生、校方利益出发
          <br />做精做专，为每一位学生负责
          <br />
        </div>
      </div>
      <div class="animation-block-3">
        <img src="@/assets/images/logo.png" />
      </div>
    </div>
    <div class="footer">
      <div class="other-href" @click="$router.push('/main')">返回</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toNext() {
      this.$router.push("/main");
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  position: relative;
  background-color: #127987;
  .cloud {
    width: 100%;
    height: 246px;
    position: absolute;
    top: 50%;
    z-index: 1;
    margin-top: -123px;
    animation: 14s linear 0s infinite normal both running
      tempKeyframesForMotion0;
    img {
      height: 100%;
    }
  }
  .birds {
    width: 87px;
    height: 44px;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 25px;
    margin-top: 88px;
    animation: 8s linear 0s infinite normal both running tempKeyframesForMotion1;
    img {
      height: 100%;
    }
  }
  .main-content {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    width: 320px;
    height: 580px;
    transform: translate(-50%, -50%);
    .animation-block-1 {
      position: absolute;
      z-index: 12;
      width: 278px;
      height: 46px;
      line-height: 46px;
      top: 21.4px;
      left: 22px;
      text-align: center;
      animation: 2s ease 0.7s 1 normal both running rollInDown;
      .text {
        padding: 5px;
        font-family: Tahoma, Helvetica, Arial;
        font-size: 24px;
        color: rgb(255, 255, 255);
      }
    }
    .animation-block-2 {
      position: absolute;
      z-index: 12;
      line-height: 1.5;
      width: 100%;
      height: 304px;
      top: 85px;
      left: 50%;
      margin-left: -50%;
      text-align: center;

      animation: 2s ease 0.8s 1 normal both running rollInUp;
      .text {
        padding: 5px;
        font-family: Tahoma, Helvetica, Arial;
        font-size: 14px;
        color: rgb(255, 255, 255);
      }
    }
    .animation-block-3 {
      position: absolute;
      z-index: 12;
      width: 100%;
      height: 57px;
      bottom: 0px;
      left: 50%;
      margin-left: -50%;
      text-align: center;
      animation: 2s ease 0.2s 1 normal both running fadeIn;
      img {
        height: 100%;
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background-image: linear-gradient(
      rgba(18, 121, 135, 0),
      rgba(18, 121, 135, 1)
    );
    padding: 20px 0;
    text-align: center;
    .other-href {
      font-size: 14px;
      color: rgb(255, 255, 255);
      text-decoration-line: underline;
      text-align: center;
      display: inline-block;
      margin: 0 auto;
      padding: 8px 16px;
      & + .other-href {
        margin-top: 10px;
      }
    }
  }
}
</style>